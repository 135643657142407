<template>

  <!--  <transition name="el-fade-in">-->
  <div id="box" class="transition-box">

    <div id="login">
      <div id="title">
        <span>{{ title }}</span>
        <span class="bg1"></span>
        <span class="bg2"></span>
      </div>
      <transition :name="transitionLogin">
        <div class="form" v-show="loginShow">
          <el-form ref="loginForm" label-width="90px" :model="loginForm" style="width: 350px;padding: 20px 0 20px 0"
                   :rules="rules">
            <el-form-item label="账号" prop="mobile">
              <el-input v-model="loginForm.mobile" placeholder="请输入账号"></el-input>
            </el-form-item>
            <el-form-item label="密码" v-show="passLoginShow" prop="password">
              <el-input type="password" autocomplete="off" v-model="loginForm.password"
                        placeholder="请输入密码"></el-input>
            </el-form-item>
            <el-form-item label="验证码" v-show="!passLoginShow" prop="authCode">
              <el-input v-model="loginForm.authCode" oninput="value=value.replace(/[^\d]/g,'')" maxlength="6"
                        placeholder="请填写短信验证码">
                <el-button style="padding-right:10px" slot="suffix" type="text" @click="sendAuthCode"
                           v-show="authCodeShow">获取验证码
                </el-button>
                <el-button style="padding-right:10px" slot="suffix" type="text" v-show="!authCodeShow">{{
                    countDownTime
                  }}s后重新发送
                </el-button>
              </el-input>
            </el-form-item>

            <el-form-item>
              <el-link type="primary" style="float: left" v-show="!passLoginShow"
                       @click="passLoginShow=true;loginForm.loginType=1"
                       class="loginType">密码登录
              </el-link>
              <el-link type="primary" style="float: left" v-show="passLoginShow"
                       @click="passLoginShow=false;loginForm.loginType=0"
                       class="loginType">验证码登录
              </el-link>
            </el-form-item>
            <el-form-item>
              <!--              <el-button type="primary"  round @click="onSubmit">登录</el-button>-->
              <el-button type="primary" style="width: 260px" @click="onSubmit" :loading="loading">
                {{ loading ? '正在登录 ...' : '提交' }}
              </el-button>
              <!--            <el-button>重置</el-button>-->
            </el-form-item>
            <el-form-item>
              <el-link type="primary" style="float: left" class="loginType" @click="loginTypeClick(0)">注册</el-link>
              <el-link type="primary" style="float: right" class="loginType" @click="loginTypeClick(2)">忘记密码？</el-link>
            </el-form-item>
          </el-form>
        </div>
      </transition>
      <transition :name="transitionRegister">
        <div v-show="registerShow">
          <register @toLogin="toLogin"/>
        </div>
      </transition>
      <transition :name="transitionRetrieve">
        <div v-show="retrieveShow">
          <retrieve @toLogin="toLogin"/>
        </div>
      </transition>
    </div>

  </div>
</template>

<script>
// import { Loading } from 'element-ui';
// import request from "@/api/request";
// import {setToken, setClerkId, setClerkName, setRoleId, setClerkTheme} from "@/utils/authUtils";
// import {getClerkTheme} from "@/api/sys/clerk";
// import VantMessage from "@/utils/massageUtils";

import {getCode, login} from "@/api/login/login";
import ElementMessageUtils from "@/utils/elementMessageUtils";
import {getToken, getUserId, setToken, setUserId, setUserName} from "@/utils/authUtils";
import {isMobile} from "@/utils/terminalUtils";
import Register from "@/views/pc/login/register";
import Retrieve from "@/views/pc/login/retrieve";

export default {
  name: "cf-login",
  data() {
    var authCode = (rule, value, callback) => {
      if (this.loginForm.loginType === 0) {
        if (value === '') {
          callback(new Error('请输入验证码'));
        } else {
          callback();
        }
      } else {
        callback();
      }

    };
    var password = (rule, value, callback) => {
      if (this.loginForm.loginType === 1) {
        if (value === '') {
          callback(new Error('请输入密码'));
        } else {
          callback();
        }
      } else {
        callback();
      }

    };
    return {
      title: '捡穗账-登录',
      registerShow: false,
      loginShow: true,
      retrieveShow: false,
      passLoginShow: false,
      loading: false,
      countDownTime: 0,
      timer: null,
      authCodeShow: true,
      transitionLogin: 'van-slide-left',
      transitionRegister: 'van-slide-right',
      transitionRetrieve: 'van-slide-right',
      loginForm: {
        authCode: '',
        mobile: '',
        password: '',
        loginType: 0,
      },
      rules: {
        mobile: [
          {required: true, message: '请输入手机号', trigger: 'blur'},
          {pattern: /^1(3\d|4[5-9]|5[0-35-9]|6[567]|7[0-8]|8\d|9[0-35-9])\d{8}$/, message: '手机号有误', trigger: 'blur'}
        ],
        password: [
          {validator: password, trigger: 'blur'},
          {min: 6, max: 16, message: '长度在 6 到 16 个字符', trigger: 'blur'}
        ],
        authCode: [
          {validator: authCode, trigger: 'blur'},
          {min: 6, max: 6, message: '验证码输入有误', trigger: 'blur'}
        ],
      }
    }
  },
  components: {
    Register,
    Retrieve
  },
  methods: {
    onSubmit() {
      this.$refs.loginForm.validate(async (valid) => {
        if (valid) {
          this.loading = true;
          let res = await login(this.loginForm);
          if (res) {
            setToken(res.token);
            setUserId(res.id);
            setUserName(res.realName);
            let this_ = this;
            setTimeout(function () {
              this_.loading = false;

              this_.$router.push("home-p");
            }, 1000);
          } else {
            this.loading = false;

          }
        }
      })

    },
    async sendAuthCode() {
      if (this.loginForm.mobile === '') {
        this.$toast("请输入手机号码")
        return false;
      }
      let res = await getCode({
        mobile: this.loginForm.mobile
      })
      if (res) {
        this.throttleFn();
      }
    },
    //函数节流
    throttleFn() {
      const TIME_COUNT = 60;
      if (!this.timer) {
        this.countDownTime = TIME_COUNT;
        this.authCodeShow = false;
        this.timer = setInterval(() => {
          if (this.countDownTime > 0 && this.countDownTime <= TIME_COUNT) {
            this.countDownTime--;
          } else {
            this.authCodeShow = true;
            clearInterval(this.timer);
            this.timer = null;
          }
        }, 1000)
      }
    },
    loginTypeClick(val) {
      let this_ = this;
      if (val === 0) {
        this.transitionLogin = 'van-slide-left';
        this.loginShow = false;
        setTimeout(function () {
          this_.transitionRegister = 'van-slide-right';
          this_.registerShow = true;
          this_.title = '捡穗账-注册'
        }, 350)
      } else {
        this.transitionLogin = 'van-slide-left';
        this.loginShow = false;
        setTimeout(function () {
          this_.transitionRetrieve = 'van-slide-right';
          this_.retrieveShow = true;
          this_.title = '捡穗账-找回密码'
        }, 350)
      }
    },
    toLogin(val) {
      if (val === 0) {
        this.transitionRegister = 'van-slide-right';
        this.registerShow = false;
        this.retrieveShow = false;
      } else {
        this.transitionRetrieve = 'van-slide-right';
        this.retrieveShow = false;
        this.registerShow = false;
      }
      let this_ = this;
      setTimeout(function () {
        this_.title = '捡穗账-登录'
        this_.transitionLogin = 'van-slide-left';
        this_.loginShow = true;
      }, 350)
    }
  },
  mounted() {
    let _this = this;
    setTimeout(() => {
      _this.show = true;
    }, 100);
    let userId = getUserId();
    let token = getToken();
    if (token && userId) {
      if (isMobile()) {
        this.$router.replace('/home');
      } else {
        this.$router.replace('/home-p');
      }
    }
    document.title = this.$route.meta.title
  }

}
</script>

<style scoped>
body {

}

.loginType {
  line-height: 16px;
}

#box {
  width: 100vw;
  height: 100vh;
  /*background-color: #148be4;*/
  /*background: url(../../static/images/loginbg.png);*/
  /*background-color: #148be4;*/
  /*height: 100%;*/
  /*width: 100%;*/

  /*margin-left: -100px;*/
  /*margin-top: -100px;*/
  text-align: center;
  /*text-align:center*/

}

#login {
  /*background-color: #2c3e50;*/
  text-align: center;
  position: absolute;
  left: 0;
  right: 0;
  top: 25%;
  bottom: 0;
  margin: auto;
  /*border: black solid 1px;*/
  /*margin:0 auto;*/
  width: 428px;
  border-radius: 12px;
  /*transform: translate(calc(-50% + 0px), calc(-50% + 0px));*/

  /*transform: translate(-50%,-50%);*/

  /*height: 350px;*/
  /*font-family: Avenir, Helvetica, Arial, sans-serif;*/
  /*-webkit-font-smoothing: antialiased;*/
  /*-moz-osx-font-smoothing: grayscale;*/
  /*color: #2c3e50;*/
  /*text-align:center;*/
  /*margin-top: 60px;*/
}

#title {
  /*width: 100%;*/
  /*height: 117px;*/
  /*background-color:#148be4;*/
  height: 117px;
  background-color: #148be4;
  border-radius: 12px 12px 0 0;
  font-family: SourceHanSansCN-Regular;
  font-size: 30px;
  font-weight: 400;
  font-stretch: normal;
  letter-spacing: 0;
  color: #fff;
  line-height: 117px;
  text-align: center;
  overflow: hidden;
  -webkit-transform: rotate(0);
  -moz-transform: rotate(0);
  -ms-transform: rotate(0);
  -o-transform: rotate(0);
  transform: rotate(0);
}

#login #title .bg1 {
  display: inline-block;
  width: 74px;
  height: 74px;
  background: #fff;
  opacity: .1;
  border-radius: 0 74px 0 0;
  position: absolute;
  left: 0;
  top: 43px;
}

#login #title .bg2 {
  display: inline-block;
  width: 94px;
  height: 94px;
  background: #fff;
  opacity: .1;
  border-radius: 50%;
  position: absolute;
  right: -16px;
  top: -16px;
}

.form {
  background-color: white;
  border-radius: 0 0 12px 12px;

}
</style>
